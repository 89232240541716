<template>
    <div class="pageContol listWrap templateList answerQuestion">
        <div class="framePage">
            <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">助学工具</a>
                    <i>></i>
                    <a
                        href="javascript:;"
                        class="cur-a"
                    >答疑列表</a>
                </span>
            </div>
            <div class="framePage-body">
                <div class="operationControl">
                    <div class="searchbox">
                        <div
                            title="问题"
                            class="searchboxItem"
                        >
                            <span class="itemLabel">问题:</span>
                            <el-input
                                v-model="questionTitle"
                                type="text"
                                size="small"
                                style="width:auto"
                                clearable
                                placeholder="请输入问题"
                            />
                        </div>
                        <div>
                            <el-button
                                class="bgc-bv"
                                @click="getData()"
                            >查询</el-button>
                        </div>
                    </div>
                    <div class="btnBox">
                        <el-button
                            class="bgc-bv"
                            @click="handleAdd()"
                        >新建答疑</el-button>
                    </div>
                </div>
                <div class="framePage-scroll">
                    <div class="ovy-a">
                        <el-table
                            ref="multipleTable"
                            :data="tableData"
                            :height="tableHeight"
                            size="small"
                            tooltip-effect="dark"
                            style="width: 100%"
                            :header-cell-style="tableHeader"
                        >
                            <el-table-column
                                label="序号"
                                align="center"
                                type="index"
                            />
                            <el-table-column
                                label="问题"
                                align="center"
                                prop="questionTitle"
                                show-overflow-tooltip
                            />
                            <el-table-column
                                label="回答"
                                align="center"
                                prop="questionAnswer"
                                show-overflow-tooltip
                            >
                                <!-- <template slot-scope="scope">
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="scope.row.questionAnswer"
                                        placement="top"
                                    >
                                        {{scope.row.questionAnswer}}
                                    </el-tooltip>
                                </template> -->

                            </el-table-column>
                            <el-table-column
                                label="状态"
                                align="center"
                            >
                                <template slot-scope="scope">
                                    <div class="flexca flexjc">
                                        <el-switch
                                            v-model="scope.row.questionState"
                                            active-value="20"
                                            inactive-value="10"
                                            @change="(val)=>{handleStu(val,scope.row)}"
                                        >
                                        </el-switch>
                                        <span style="margin-left:5px">{{scope.row.questionState == '10'?'隐藏':'显示'}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                label="操作"
                                align="center"
                                width="160px"
                            >
                                <div
                                    slot-scope="scope"
                                    class="flexcc"
                                >
                                    <el-button
                                        size="mini"
                                        type="text"
                                        @click="handleEdit(scope.row)"
                                    >编辑</el-button>
                                    <el-button
                                        size="mini"
                                        type="text"
                                        @click="handleDel(scope.row.questionId)"
                                    >删除</el-button>
                                </div>
                            </el-table-column>
                            <Empty slot="empty" />
                        </el-table>
                    </div>
                </div>
                <PageNum
                    :apiData="apiData"
                    @sizeChange="sizeChange"
                    @getData="getData"
                />
            </div>
        </div>
        <el-dialog
            :title="questionId?'编辑答疑':'新增答疑'"
            :visible.sync="dialogFormVisible"
            :before-close="close"
            center
            width="40%"
        >
            <el-form
                ref="form"
                :model="form"
                :rules="rule"
                label-width="100px"
            >
                <el-form-item
                    label="问题："
                    prop="questionTitle"
                >
                    <el-input
                        v-model="form.questionTitle"
                        size="small"
                    ></el-input>
                </el-form-item>
                <el-form-item
                    label="回答："
                    prop="questionAnswer"
                >
                    <el-input
                        v-model="form.questionAnswer"
                        size="small"
                        type="textarea"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button @click="close">取 消</el-button>
                <el-button
                    class="bgc-bv"
                    @click="doSave"
                >确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
    name: "answerQuestion",
    components: {
        Empty,
        PageNum,
    },
    mixins: [List],
    data() {
        return {
            dialogFormVisible: false,
            questionTitle: "",
            questionId: "",
            form: {
                questionTitle: "",
                questionAnswer: "",
            },
            rule: {
                questionTitle: [
                    { required: true, message: "请输入问题", trigger: "bulr" },
                ],
                questionAnswer: [
                    { required: true, message: "请输入回答", trigger: "bulr" },
                ],
            },
        };
    },
    computed: {},
    methods: {
        getData(pageNum = 1) {
            const params = {
                pageNum: pageNum,
                pageSize: this.pageSize,
                questionTitle: this.questionTitle,
            };
            this.doFetch(
                {
                    url: "/quiestionanswer/queryPage",
                    params,
                    pageNum,
                },
                true,
                2
            );
        },
        // 新增
        handleAdd() {
            this.dialogFormVisible = true;
            this.questionId = "";
        },
        // 编辑
        handleEdit(row) {
            this.dialogFormVisible = true;
            this.form.questionAnswer = row.questionAnswer;
            this.form.questionTitle = row.questionTitle;
            this.questionId = row.questionId;
        },
        doSave() {
            let url = "";
            let params = {
                questionTitle: this.form.questionTitle,
                questionAnswer: this.form.questionAnswer,
            };
            if (this.questionId) {
                url = "/quiestionanswer/modify";
                params.questionId = this.questionId;
            } else {
                url = "/quiestionanswer/insert";
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.$post(url, params, 3000, true, 2).then((res) => {
                        this.$message({
                            type: "success",
                            message: res.message,
                        });
                        if (this.questionId) {
                            this.getData(-1);
                        } else {
                            this.getData();
                        }
                        this.close();
                    });
                }
            });
        },
        // 关闭
        close() {
            this.questionId = "";
            this.dialogFormVisible = false;
            this.form = {
                questionTitle: "",
                questionAnswer: "",
            };
            this.$refs.form.clearValidate();
        },
        // 修改状态
        handleStu(val, row) {
            this.$post(
                "/quiestionanswer/modify",
                {
                    questionId: row.questionId,
                    questionState: val,
                },
                3000,
                true,
                2
            ).then((res) => {
                // ;
                this.$message({
                    type: "success",
                    message: res.message,
                });
            });
        },
        // 删除
        handleDel(questionId) {
            this.$confirm("删除后，将不可恢复", "确定删除吗？", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$post(
                        "/quiestionanswer/delete",
                        { questionId },
                        3000,
                        true,
                        2
                    ).then((res) => {
                        if (res.status == "0") {
                            this.$message({
                                type: "success",
                                message: res.message,
                            });
                            this.getData(-1);
                        }
                    });
                })
                .catch(() => {
                    return;
                });
        },
    },
};
</script>
<style lang="less" >
.el-tooltip__popper {
    max-width: 50% !important;
}
</style>
